import httpRequest from './httpRequest';

/**
 * 用户协议
 * @parma { type  } type 协议类型:1：注册协议 2：隐私政策
 * **/
export const userAgreement = type => {
    return httpRequest({
        url: '/user/userAgreement',
        method: 'post',
        data: {
            type
        }
    });
};
