<template>
    <div class="page-agreement">
        <div class="inner" v-if="detail" v-html="detail.content"></div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { userAgreement } from '@/api/user';
export default {
    name: 'PageAgreement',
    data() {
        return {
            detail: null
        };
    },
    metaInfo() {
        return {
            title: this.detail ? this.detail.name : ''
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            Toast.loading({ duration: 0, message: '加载中' });
            let { type = 2 } = this.$route.query;
            userAgreement(type).then(res => {
                if (res && '999' === res.code) {
                    Toast.clear();
                    this.detail = res.data;
                }
            });
        }
    }
};
</script>

<style lang="less" scroped>
.page-agreement {
    max-width: 750px;
    margin: 0 auto;
    padding: 30px 14px;
    & > div {
        font-size: 30px;
        color: #5b5b5b;
        margin-bottom: 20px;
        text-align: justify;
    }
    .ql-align-center {
        text-align: center !important;
    }
}
</style>
